import React from 'react';

export const maintenancePlanPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/maintenance-plan',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-backlogs',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-condition-monitoring',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-improvement-product',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-inspection',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-maintenance-interval',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-mayor-component',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-minor-component',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-plan',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-request-backlog',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-send',
        component: React.lazy(() => import('./Page')),
      },
      {
        path: '/maintenance-plan-alerts',
        component: React.lazy(() => import('./Page')),
      },
    ],
  },
];
