import { all } from "redux-saga/effects";
import {
  sendMaintenancePlan,
  ChangeStockMaterials,
  StockMaterials,
  AddStockMaterials,
  Materials,
  AdministrativeUsers,
  Test,
  OperatorsUsers,
  MaintenanceStaffUsers,
  branch,
  listReserveTime,
  listShift,
  listSpecialitys,
  listTypeHolidays,
  listTypeWork,
  listTypeVehicles,
  listBrand,
  listModels,
  SpareParts,
  StockSpareParts,
  AddStockSpareParts,
  ChangeStockSpareParts,
  WorkOrder,
  WorkOrderInform,
  LaborRegistration,
  LaborRegistrationInform,
  Fleets,
  Vehicles,
  Systems,
  Components,
  Operations,
  Backlogs,
  listOperationalLost,
  NoProductionDays,
  listTypeUser,
  requestBacklogs,
  listUnitMeasurement,
  listPosition,
  listShiftSystems,
  GraphicsMaintenanceVehicle,
  GraphicsMaintenanceEquipment,
  GraphicsOperations,
  GraphicsWorkOrder,
  GraphicsMaintenanceProgramMonth,
  GraphicsMaintenanceProgramWeek,
  GraphicsMaintenanceCostFleet,
  GraphicsMaintenanceCostVehicle,
  GraphicsMaintenanceCostSystem,
  GraphicsOil,
  GraphicsBacklogs,
  GraphicsStockLeadTime,
  Oil,
  KmHr,
  WasteOperational,
  ReserveTimes,
  RequestMaterials,
  RequestSpareParts,
  TransferSpareParts,
  Companies,
  Licences,
  RequestTransferSpareParts,
  MaintenanceInterval,
  ConditionMonitoring,
  MinorComponentsReplacement,
  MayorComponentsReplacement,
  ImprovementProduct,
  Inspection,
  LaborRegistrationWithOt,
  Currency,
  listGroups,
  MassiveUploadVehicles,
  MassiveUploadSpareParts,
  MassiveUploadWorkOrder,
  modifyFiles,
  Activities,
  supplier,
  supplierMaterials,
  modifyFilesDocument,
  StockSparePartOtherBranch,
  GraphicsLabor,
  GraphicsCriticality,
  GraphicsCriticalitySystems,
  GraphicsCriticalityComponents,
  GraphicsFailureCause,
  WorkOrdersHistorical,
  AddStockOt,
  LaborRegistrationWithOtJustList,
  CreateWorkOrdersExcel,
  MassiveUploadOperator,
  MassiveUploadMaintenance,
  UserCompanies,
  branchCompanies,
  listPositionCompanies,
  MaintenanceStaffUsersSpecial,
  SparePartOtherBranch,
  WorkOrderKmHr,
  GraphicsStockRotationMonthly,
  GraphicsStockRotationAnnually,
  FleetsOtherBranch,
  ActivitiesTwo,
  MassiveUploadStockSpareParts,
  MassiveUploadTransferSpareParts,
  MassiveUploadTransferMaterials,
  MassiveUploadKmHr,
  MassiveUploadOil,
  InformUseMaterialsWithOut,
  MaterialsWithOutOt,
  notifications,
  MassiveUploadMaterials,
  MassiveUploadStockMaterials,
  MassiveUploadSystems,
  MassiveUploadAdjustStockMaterials,
  MassiveUploadAdjustStockSparePart,
  MassiveUploadSupplier,
  ActivitiesThisMonth,
  MassiveUploadMaterialSuppliers,
  SparePartEntryReport,
  MaterialEntryReport,
  AlertsPlan,
} from "../builder";

import authSagas from "./auth";
//reports
const sparePartEntryReportSagas = SparePartEntryReport.getSagas().sagas();
const materialEntryReportSagas = MaterialEntryReport.getSagas().sagas();
//
const testSagas = Test.getSagas().sagas();
const graphicsCriticalitySagas = GraphicsCriticality.getSagas().sagas();
const graphicsCriticalitySystemsSagas =
  GraphicsCriticalitySystems.getSagas().sagas();
const GraphicsCriticalityComponentsSagas =
  GraphicsCriticalityComponents.getSagas().sagas();
const GraphicsFailureCausesSagas = GraphicsFailureCause.getSagas().sagas();
//
const GraphicsLaborSagas = GraphicsLabor.getSagas().sagas();
// files
const modifyFilesSagas = modifyFiles.getSagas().sagas();
const modifyFilesDocumentSagas = modifyFilesDocument.getSagas().sagas();
// upload massive
const MassiveUploadVehiclesSagas = MassiveUploadVehicles.getSagas().sagas();
const MassiveUploadSystemsSagas = MassiveUploadSystems.getSagas().sagas();
const MassiveUploadSparePartsSagas = MassiveUploadSpareParts.getSagas().sagas();
const MassiveUploadStockSparePartsSagas =
  MassiveUploadStockSpareParts.getSagas().sagas();
const MassiveUploadTransferSparePartsSagas =
  MassiveUploadTransferSpareParts.getSagas().sagas();
const MassiveUploadTransferMaterialsSagas =
  MassiveUploadTransferMaterials.getSagas().sagas();
const MassiveUploadMaterialsSagas = MassiveUploadMaterials.getSagas().sagas();
const MassiveUploadAdjustStockMaterialsSagas =
  MassiveUploadAdjustStockMaterials.getSagas().sagas();
const MassiveUploadAdjustStockSparePartSagas =
  MassiveUploadAdjustStockSparePart.getSagas().sagas();
const MassiveUploadSupplierSagas = MassiveUploadSupplier.getSagas().sagas();
const MassiveUploadStockMaterialsSagas =
  MassiveUploadStockMaterials.getSagas().sagas();
const MassiveUploadWorkOrderSagas = MassiveUploadWorkOrder.getSagas().sagas();
const MassiveUploadKmHrSagas = MassiveUploadKmHr.getSagas().sagas();
const MassiveUploadOilSagas = MassiveUploadOil.getSagas().sagas();
const MassiveUploadOperatorSagas = MassiveUploadOperator.getSagas().sagas();
const MassiveUploadMaintenanceSagas =
  MassiveUploadMaintenance.getSagas().sagas();
const MassiveUploadMaterialSuppliersSagas =
  MassiveUploadMaterialSuppliers.getSagas().sagas();
// materials
const MaterialsSagas = Materials.getSagas().sagas();
const RequestMaterialsSagas = RequestMaterials.getSagas().sagas();
const StockMaterialsSagas = StockMaterials.getSagas().sagas();
const InformUseMaterialsWithOutSagas =
  InformUseMaterialsWithOut.getSagas().sagas();
const ChangeStockMaterialsSagas = ChangeStockMaterials.getSagas().sagas();
const MaterialsWithOutOtSagas = MaterialsWithOutOt.getSagas().sagas();
const AddStockMaterialsSagas = AddStockMaterials.getSagas().sagas();
// users
const AdministrativeUsersSagas = AdministrativeUsers.getSagas().sagas();
const operatorsUsersSagas = OperatorsUsers.getSagas().sagas();
const maintenanceStaffUsersSagas = MaintenanceStaffUsers.getSagas().sagas();
const maintenanceStaffUsersSpecialSagas =
  MaintenanceStaffUsersSpecial.getSagas().sagas();
// setting
const notificationsSagas = notifications.getSagas().sagas();
const supplierSagas = supplier.getSagas().sagas();
const supplierMaterialsSagas = supplierMaterials.getSagas().sagas();
const branchSagas = branch.getSagas().sagas();
const listReserveTimeSagas = listReserveTime.getSagas().sagas();
const listOperationalLostSagas = listOperationalLost.getSagas().sagas();
const listShiftUsersSagas = listShift.getSagas().sagas();
const listSpecialitysSagas = listSpecialitys.getSagas().sagas();
const listTypeHolidaysSagas = listTypeHolidays.getSagas().sagas();
const listTypeWorkSagas = listTypeWork.getSagas().sagas();
const listPositionSagas = listPosition.getSagas().sagas();
const listTypeVehiclesSagas = listTypeVehicles.getSagas().sagas();
const listBrandSagas = listBrand.getSagas().sagas();
const listModelsSagas = listModels.getSagas().sagas();
const listTypeUserSagas = listTypeUser.getSagas().sagas();
const listUnitMeasurementSagas = listUnitMeasurement.getSagas().sagas();
const listShiftSystemsSagas = listShiftSystems.getSagas().sagas();
const listGroupsSagas = listGroups.getSagas().sagas();
// work order
const workOrderSagas = WorkOrder.getSagas().sagas();
const workOrderKmHrSagas = WorkOrderKmHr.getSagas().sagas();
const workOrderInformSagas = WorkOrderInform.getSagas().sagas();
const workOrdersHistoricalSagas = WorkOrdersHistorical.getSagas().sagas();
const createWorkOrdersExcelSagas = CreateWorkOrdersExcel.getSagas().sagas();
const addStockOtSagas = AddStockOt.getSagas().sagas();
// labor register
const laborRegistrationSagas = LaborRegistration.getSagas().sagas();
const laborRegistrationWithOtJustListSagas =
  LaborRegistrationWithOtJustList.getSagas().sagas();
const laborRegistrationWithOtSagas = LaborRegistrationWithOt.getSagas().sagas();
const laborRegistrationInformSagas = LaborRegistrationInform.getSagas().sagas();
// spare parts
const sparePartsSagas = SpareParts.getSagas().sagas();
const requestSparePartsSagas = RequestSpareParts.getSagas().sagas();
const TransferSparePartsSagas = TransferSpareParts.getSagas().sagas();
const RequestTransferSparePartsSagas =
  RequestTransferSpareParts.getSagas().sagas();
const stockSparePartsSagas = StockSpareParts.getSagas().sagas();
const changeStockSparePartsSagas = ChangeStockSpareParts.getSagas().sagas();
const AddStockSparePartsSagas = AddStockSpareParts.getSagas().sagas();
// vehicles
const fleetsSagas = Fleets.getSagas().sagas();
const vehiclesSagas = Vehicles.getSagas().sagas();
const systemsSagas = Systems.getSagas().sagas();
const componentsSagas = Components.getSagas().sagas();
// operations
const operationsSagas = Operations.getSagas().sagas();
const noProductionDaysSagas = NoProductionDays.getSagas().sagas();
const oilSagas = Oil.getSagas().sagas();
const kmHrSagas = KmHr.getSagas().sagas();
const wasteOperationalSagas = WasteOperational.getSagas().sagas();
const reserveTimesSagas = ReserveTimes.getSagas().sagas();
// activities
const activitiesSagas = Activities.getSagas().sagas();
const activitiesTwoSagas = ActivitiesTwo.getSagas().sagas();
const activitiesThisMonthSagas = ActivitiesThisMonth.getSagas().sagas();
const sendMaintenancePlanSagas = sendMaintenancePlan.getSagas().sagas();
const alertsPlanSagas = AlertsPlan.getSagas().sagas();
const backlogsSagas = Backlogs.getSagas().sagas();
const requestBacklogsSagas = requestBacklogs.getSagas().sagas();
const MaintenanceIntervalSagas = MaintenanceInterval.getSagas().sagas();
const ConditionMonitoringSagas = ConditionMonitoring.getSagas().sagas();
const MinorComponentsReplacementSagas =
  MinorComponentsReplacement.getSagas().sagas();
const MayorComponentsReplacementSagas =
  MayorComponentsReplacement.getSagas().sagas();
const ImprovementProductSagas = ImprovementProduct.getSagas().sagas();
const InspectionSagas = Inspection.getSagas().sagas();
// graphic
const graphicsMaintenanceVehicleAvailabilitySagas =
  GraphicsMaintenanceVehicle.getSagas().sagas();
const graphicsMaintenanceEquipmentAvailabilitySagas =
  GraphicsMaintenanceEquipment.getSagas().sagas();
// operations
const GraphicsOperationsUseSagas = GraphicsOperations.getSagas().sagas();
// work order
const GraphicsWorkOrderSagas = GraphicsWorkOrder.getSagas().sagas();
// maintenance program
const GraphicsMaintenanceProgramWeekSagas =
  GraphicsMaintenanceProgramWeek.getSagas().sagas();
const GraphicsMaintenanceProgramMonthSagas =
  GraphicsMaintenanceProgramMonth.getSagas().sagas();
// maintenance cost
const GraphicsMaintenanceCostFleetSagas =
  GraphicsMaintenanceCostFleet.getSagas().sagas();
const GraphicsMaintenanceCostVehicleSagas =
  GraphicsMaintenanceCostVehicle.getSagas().sagas();
const GraphicsMaintenanceCostSystemSagas =
  GraphicsMaintenanceCostSystem.getSagas().sagas();
// oil
const GraphicsOilSagas = GraphicsOil.getSagas().sagas();
// backlogs
const GraphicsBacklogsSagas = GraphicsBacklogs.getSagas().sagas();
// stock
const GraphicsStockLeadTimeMonthSagas =
  GraphicsStockLeadTime.getSagas().sagas();
const GraphicsStockRotationMonthlySagas =
  GraphicsStockRotationMonthly.getSagas().sagas();
const GraphicsStockRotationAnnuallySagas =
  GraphicsStockRotationAnnually.getSagas().sagas();
// admin
const CompaniesSagas = Companies.getSagas().sagas();
const UserCompaniesSagas = UserCompanies.getSagas().sagas();
const BranchCompaniesSagas = branchCompanies.getSagas().sagas();
const ListPositionCompaniesSagas = listPositionCompanies.getSagas().sagas();
const LicencesSagas = Licences.getSagas().sagas();
const CurrencySagas = Currency.getSagas().sagas();
// stock spare part other branch
const stockSparePartOtherBranchSagas =
  StockSparePartOtherBranch.getSagas().sagas();
const sparePartOtherBranchSagas = SparePartOtherBranch.getSagas().sagas();
const FleetsOtherBranchSagas = FleetsOtherBranch.getSagas().sagas();

export default function* rootSaga(getState) {
  yield all([
    graphicsCriticalitySagas(),
    graphicsCriticalitySystemsSagas(),
    GraphicsCriticalityComponentsSagas(),
    GraphicsFailureCausesSagas(),
    GraphicsLaborSagas(),
    stockSparePartOtherBranchSagas(),
    sparePartOtherBranchSagas(),
    FleetsOtherBranchSagas(),
    modifyFilesSagas(),
    modifyFilesDocumentSagas(),
    MassiveUploadKmHrSagas(),
    MassiveUploadOilSagas(),
    MassiveUploadVehiclesSagas(),
    MassiveUploadSystemsSagas(),
    MassiveUploadSparePartsSagas(),
    MassiveUploadStockSparePartsSagas(),
    MassiveUploadTransferSparePartsSagas(),
    MassiveUploadTransferMaterialsSagas(),
    MassiveUploadAdjustStockMaterialsSagas(),
    MassiveUploadAdjustStockSparePartSagas(),
    MassiveUploadSupplierSagas(),
    MassiveUploadMaterialsSagas(),
    MassiveUploadStockMaterialsSagas(),
    MassiveUploadWorkOrderSagas(),
    MassiveUploadOperatorSagas(),
    MassiveUploadMaintenanceSagas(),
    MassiveUploadMaterialSuppliersSagas(),
    MaintenanceIntervalSagas(),
    laborRegistrationWithOtSagas(),
    ConditionMonitoringSagas(),
    MinorComponentsReplacementSagas(),
    MayorComponentsReplacementSagas(),
    ImprovementProductSagas(),
    InspectionSagas(),
    CompaniesSagas(),
    BranchCompaniesSagas(),
    ListPositionCompaniesSagas(),
    UserCompaniesSagas(),
    LicencesSagas(),
    CurrencySagas(),
    oilSagas(),
    kmHrSagas(),
    wasteOperationalSagas(),
    reserveTimesSagas(),
    GraphicsStockLeadTimeMonthSagas(),
    GraphicsStockRotationMonthlySagas(),
    GraphicsStockRotationAnnuallySagas(),
    GraphicsBacklogsSagas(),
    GraphicsOilSagas(),
    GraphicsMaintenanceCostFleetSagas(),
    GraphicsMaintenanceCostVehicleSagas(),
    GraphicsMaintenanceCostSystemSagas(),
    GraphicsMaintenanceProgramWeekSagas(),
    GraphicsMaintenanceProgramMonthSagas(),
    GraphicsWorkOrderSagas(),
    GraphicsOperationsUseSagas(),
    graphicsMaintenanceVehicleAvailabilitySagas(),
    graphicsMaintenanceEquipmentAvailabilitySagas(),
    testSagas(),
    MaterialsSagas(),
    RequestMaterialsSagas(),
    StockMaterialsSagas(),
    InformUseMaterialsWithOutSagas(),
    AddStockMaterialsSagas(),
    ChangeStockMaterialsSagas(),
    MaterialsWithOutOtSagas(),
    AdministrativeUsersSagas(),
    operatorsUsersSagas(),
    maintenanceStaffUsersSagas(),
    maintenanceStaffUsersSpecialSagas(),
    notificationsSagas(),
    supplierSagas(),
    supplierMaterialsSagas(),
    branchSagas(),
    listOperationalLostSagas(),
    listReserveTimeSagas(),
    listShiftUsersSagas(),
    listSpecialitysSagas(),
    listTypeHolidaysSagas(),
    listTypeWorkSagas(),
    listPositionSagas(),
    listTypeVehiclesSagas(),
    listBrandSagas(),
    listModelsSagas(),
    listTypeUserSagas(),
    listShiftSystemsSagas(),
    listGroupsSagas(),
    listUnitMeasurementSagas(),
    workOrderKmHrSagas(),
    workOrderSagas(),
    workOrderInformSagas(),
    workOrdersHistoricalSagas(),
    createWorkOrdersExcelSagas(),
    addStockOtSagas(),
    laborRegistrationSagas(),
    laborRegistrationWithOtJustListSagas(),
    laborRegistrationInformSagas(),
    sparePartsSagas(),
    requestSparePartsSagas(),
    TransferSparePartsSagas(),
    RequestTransferSparePartsSagas(),
    stockSparePartsSagas(),
    AddStockSparePartsSagas(),
    changeStockSparePartsSagas(),
    fleetsSagas(),
    vehiclesSagas(),
    systemsSagas(),
    componentsSagas(),
    operationsSagas(),
    noProductionDaysSagas(),
    backlogsSagas(),
    activitiesSagas(),
    activitiesTwoSagas(),
    activitiesThisMonthSagas(),
    sendMaintenancePlanSagas(),
    alertsPlanSagas(),
    requestBacklogsSagas(),
    authSagas(),
    sparePartEntryReportSagas(),
    materialEntryReportSagas(),
  ]);
}
